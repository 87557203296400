import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { AppConfigModel } from './model/app-config.model';

@Injectable()
export class AppConfig {
  static settings: AppConfigModel;

  constructor(private http: HttpClient) { }

  load() {
    const jsonFile = 'assets/config/config.json';
    return new Promise<void>((resolve, reject) => {
      this.http.get(jsonFile).toPromise().then((response: AppConfigModel) => {
        AppConfig.settings = response as AppConfigModel;
        resolve();
      }).catch((response: any) => {
        reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
      });
    });
  }
}
