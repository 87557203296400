export enum RuoloUtenteEnum {
  ADMIN = 'ADMIN',
  // OPERATOR = 'OPERATOR',
  // VIEWER = 'VIEWER',
  SOSTA_VISUALIZZATORE = 'SOSTA_VISUALIZZATORE',
  SOSTA_MODIFICA = 'SOSTA_MODIFICA',
  SOSTA_SAP = 'SOSTA_SAP',
  ORDINE_SERVIZIO_VISUALIZZATORE = 'ORDINE_SERVIZIO_VISUALIZZATORE',
  ORDINE_SERVIZIO_MODIFICA = 'ORDINE_SERVIZIO_MODIFICA',
  ORDINE_SERVIZIO_SAP = 'ORDINE_SERVIZIO_SAP',
  NEGOZIO_VISUALIZZATORE = 'NEGOZIO_VISUALIZZATORE',
  NEGOZIO_MODIFICA = 'NEGOZIO_MODIFICA',
  NEGOZIO_SAP = 'NEGOZIO_SAP',
  SICUREZZA_VISUALIZZATORE = 'SICUREZZA_VISUALIZZATORE',
  SICUREZZA_MODIFICA = 'SICUREZZA_MODIFICA',
  ANAGRAFICA = 'ANAGRAFICA',
  PFSO = 'PFSO',
  VENDITORE = 'VENDITORE',
  VENDITORE_BACKOFFICE = 'VENDITORE_BACKOFFICE',
  CONTROLLORE_ACCESSI = 'CONTROLLORE_ACCESSI',
  SICUREZZA = 'SICUREZZA',
  SICUREZZA_SAP = 'SICUREZZA_SAP',
  OPERAIO = 'OPERAIO',
  CONTROLLO_SERVIZIO_QUALITA = 'CONTROLLO_SERVIZIO_QUALITA',
  APPROVATORE_PSC_PRIMO_LIVELLO = 'APPROVATORE_PSC_PRIMO_LIVELLO',
  APPROVATORE_PSC_SECONDO_LIVELLO = 'APPROVATORE_PSC_SECONDO_LIVELLO',
  APPROVATORE_BANCHINA_RONDA = 'APPROVATORE_BANCHINA_RONDA',
  APPROVATORE_CONTROLLO_QUALITA_PRIMO_LIVELLO = 'APPROVATORE_CONTROLLO_QUALITA_PRIMO_LIVELLO',
  APPROVATORE_CONTROLLO_QUALITA_SECONDO_LIVELLO = 'APPROVATORE_CONTROLLO_QUALITA_SECONDO_LIVELLO',
  SEMIRIMORCHIO_VISUALIZZATORE = 'SEMIRIMORCHIO_VISUALIZZATORE',
  SEMIRIMORCHIO_MODIFICA = 'SEMIRIMORCHIO_MODIFICA',
  SEMIRIMORCHIO_SAP = 'SEMIRIMORCHIO_SAP',
}

export enum OperaioSubRuoliEnum {
  OP_SUPER = 'Op. Supervisore',
  OPERATORE = 'Operatore',
  FACCHINO = 'Facchino'
}

export enum RuoloDipendenteEnum {
  'HOSTESS' = 'Shuttle Bus',
  'HOSTESS-CT' = 'Hostess Supervisore',
  'ACCOGLIENZA' = 'Accoglienza',
  'GUARDIA GIURATA' = 'Guardia Giurata',
  'GUARDIA GIURATA-CT' = 'Guardia Giurata Supervisore',
  'OPERAIO' = 'Operaio',
  'OPERAIO-CT' = 'Operaio Supervisore',
  'FACCHINO' = 'Facchino',
}

export enum SecuritySubRuoliEnum {
  SEC_SUPER = 'Sec. Supervisore',
  SEC_ACCES = 'Sec. Accessi',
  SEC_RX = 'Sec. Rx',
  SEC_USC_TERM = 'Sec. Usc. Terminal'
}

export enum HostessSubRuoliEnum {
  HOSTS = 'Shuttle Bus',
  ACCOGLIENZA = 'Accoglienza',
}

export enum TipoBiglietto {
  BIGLIETTO = 'BIGLIETTO',
  ABBONAMENTO = 'ABBONAMENTO',
  CARNET = 'CARNET',
}

export enum TipoSicurezza {
  BANCHINA = 'BANCHINA',
  PSC_CRO = 'PSC_CRO',
  PSC_CRO_IMB = 'PSC_CRO_IMB',
  PSC_CRO_SBA = 'PSC_CRO_SBA',
  PSC_TRG = 'PSC_TRG',
  PSC_TRG_IMB = 'PSC_TRG_IMB',
  PSC_TRG_SBA = 'PSC_TRG_SBA',
  SEMIRIMORCHI = 'SEMIRIMORCHI',
  RONDA = 'RONDA',
}

export enum StatoSicurezzaAreaPorto {
  DA_APPROVARE = 'DA_APPROVARE',
  APPROVATO = 'APPROVATO',
  APPROVATO_PRIMO_LIVELLO = 'APPROVATO_PRIMO_LIVELLO'
}
