import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppConfig } from './app-config';
import { OWL_DATE_TIME_LOCALE, OwlDateTimeIntl } from 'ng-pick-datetime';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpInterceptorService } from './interceptor/httpinterceptor.service';
import { OWL_COLOR_PICKER_SCROLL_STRATEGY_PROVIDER_FACTORY } from 'owl-ng/color-picker/color-picker.component';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { HttpUtilService } from './services/http/http.util.service';
import { AuthGuard } from './backoffice/components/common/guards/auth.guard';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSnackBarComponent } from './backoffice/components/mat-snack-bar/mat-snack-bar.component';
import { MatButtonModule } from '@angular/material/button';
import { LoginGuard } from './backoffice/components/common/guards/login.guard';
import { AdminGuard } from './backoffice/components/common/guards/admin.guard';
import { AnagraficaGuard } from './backoffice/components/common/guards/anagrafica.guard';
import { NegozioViewGuard } from './backoffice/components/common/guards/negozioView.guard';
import { NegozioModificaGuard } from './backoffice/components/common/guards/negozioModifica.guard';
import { OrdineServizioViewGuard } from './backoffice/components/common/guards/ordineServizioView.guard';
import { OrdineServizioModificaGuard } from './backoffice/components/common/guards/ordineServizioModifica.guard';
import { PfsoGuard } from './backoffice/components/common/guards/pfso.guard';
import { SicurezzaViewGuard } from './backoffice/components/common/guards/sicurezzaView.guard';
import { SicurezzaModificaGuard } from './backoffice/components/common/guards/sicurezzaModifica.guard';
import { SostaViewGuard } from './backoffice/components/common/guards/sostaView.guard';
import { SostaModificaGuard } from './backoffice/components/common/guards/sostaModifica.guard';
import { MatDialogModule, MatProgressBarModule } from '@angular/material';
import { ControlloServizioQualita } from './backoffice/components/common/guards/controlloServizioQualita.guard';
import { AnagraficaSicurezzaGuard } from './backoffice/components/common/guards/anagrafica.sicurezza.guard';
import { AnagraficaSemirimorchioGuard } from './backoffice/components/common/guards/anagrafica.semirimorchio.guard';
import { SemirimorchioGuard } from './backoffice/components/common/guards/semirimorchio.guard';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

export class MyIntlLabels extends OwlDateTimeIntl {
  public cancelBtnLabel = 'Annulla';
  public setBtnLabel = 'Conferma';
  public rangeFromLabel = 'Dal Giorno';
  public rangeToLabel = 'Al Giorno';
}

@NgModule({
  declarations: [
    AppComponent,
    MatSnackBarComponent,
  ],
  exports: [],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressBarModule
  ],
  bootstrap: [AppComponent],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig], multi: true
    },
    { provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'it'},
    {provide: OWL_COLOR_PICKER_SCROLL_STRATEGY_PROVIDER_FACTORY, useValue: 'it'},
    {provide: OwlDateTimeIntl, useClass: MyIntlLabels},
    DatePipe,
    HttpUtilService,
    AuthGuard,
    CurrencyPipe,
    LoginGuard,
    AdminGuard,
    AnagraficaGuard,
    AnagraficaSicurezzaGuard,
    NegozioViewGuard,
    NegozioModificaGuard,
    OrdineServizioViewGuard,
    OrdineServizioModificaGuard,
    PfsoGuard,
    SicurezzaViewGuard,
    SicurezzaModificaGuard,
    SostaViewGuard,
    SostaModificaGuard,
    ControlloServizioQualita,
    AnagraficaSemirimorchioGuard,
    SemirimorchioGuard
  ],
  entryComponents: [
    MatSnackBarComponent,
  ]
})
export class AppModule {
}
