import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Utente } from '../../model/utente-loggato.model';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../app-config';
import { Router } from '@angular/router';
import { AlertsService } from '../alerts/alerts.service';
import { MatDialog } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUtenteLoggatoSubject: BehaviorSubject<Utente>;
  public currentUtente: Observable<Utente>;

  constructor(private http: HttpClient,
              private router: Router,
              private alertService: AlertsService,
              private dialog: MatDialog) {
    this.currentUtenteLoggatoSubject = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('currentUtente')));
    this.currentUtente = this.currentUtenteLoggatoSubject.asObservable();
  }
  currentUser() {
    const utente = JSON.parse(sessionStorage.getItem('currentUtente')) as Utente;
    return utente;
  }

  login(username: string, password: string) {
    const url: string = AppConfig.settings.restServer.baseUrl + AppConfig.settings.endPointUtente.login;
    const payload = new HttpParams()
      .set('username', username)
      .set('password', password);
    return this.http.post<any>(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    })
      .pipe(map(user => {
        sessionStorage.setItem('currentUtente', JSON.stringify(user.utente));
        this.currentUtenteLoggatoSubject.next(user.utente);
        return user;
      }));
  }

  logout() {
    const url: string = AppConfig.settings.restServer.baseUrl + AppConfig.settings.endPointUtente.logout;
    return this.http.post<any>(url, null)
      .pipe(map(result => {
        sessionStorage.removeItem('currentUtente');
        this.currentUtenteLoggatoSubject.next(null);
        return result;
      }));
  }

  forceLogout() {
    this.currentUtenteLoggatoSubject.next(null);
    setTimeout(() => this.alertService.closeSnackBar(), 4000);
    sessionStorage.clear();
    if (this.router.url.indexOf('/portale-accessi') > -1) {
      this.router.navigate(['/portale-accessi/login']);
    } else {
      this.router.navigate(['/app/login']);
    }
  }

  forceLogoutCloseDialog() {
    this.dialog.closeAll();
    this.forceLogout();
  }
}
